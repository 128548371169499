<template>
  <div class="change-history" :class="{skeleton: !state.loaded}">
    <div class="container" v-if="$store.state.account.loggedIn">
      <div class="wrapper">
        <div class="header">
          <div class="wrapper">
            <select class="form-control border-focus-point font-sm" v-model="state.historySeq" @change="select()">
              <option :value="h.historySeq" v-for="(h, idx) in state.histories" :key="idx">{{ h.activateTime }}</option>
            </select>
            <b class="title font-xl">{{ state.history.title }}</b>
          </div>
        </div>
        <div class="article">
          <div class="content">
            <div v-html="state.history.content"></div>
          </div>
          <div class="info font-sm">
            <p>
              <span>* 자본시장법 시행령 제68조제5홍제13호의4에 의거, 투자판단에 큰 영향을 미칠 수 있는 중요사항이 변경되는 경우(자금의 사용목적, 재무제표, 중요한 소송제기 등) 투자를 완료한 기존 투자자들에게 위 내용을 통지하고 투자의사를 재확인하도록 의무화되었습니다.</span>
            </p>
            <div class="color-danger">
              <span>청약기간 내에 투자의사를 재확인하지 않으실 경우 투자는 자동 취소됩니다.</span>
            </div>
          </div>
        </div>
        <div class="actions" v-if="state.isExistSubscribe && !state.isRespondedToChange && state.isLatestChange">
          <div class="row">
            <div class="col-md-6">
              <button class="btn btn-secondary btn-block font-sm" @click="disagree()">변경 내용에 동의하지 않으므로 기존 투자를 취소합니다.</button>
            </div>
            <div class="col-md-6">
              <button class="btn btn-point btn-block font-sm" @click="agree()">
                <b>변경 내용에 동의하며 기존 투자를 유지합니다.</b>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Loading :opaque="true" v-else-if="$store.state.account.checked"/>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import mixin from "@/scripts/mixin";
import router from "@/scripts/router";
import store from "@/scripts/store";
import caches from "@/scripts/caches";
import Loading from "@/components/Loading.vue";

function Component(initialize) {
  this.name = "pageInvestChangeHistory";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Loading},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      if (!store.state.account.loggedIn) {
        return store.dispatch("goLoginPage");
      }

      state.historySeq = window.Number(router.app.$route.params.historySeq);
      state.history.title = "Wait a moment";
      state.history.content = "<p>Wait a moment</p>";

      state.loaded = false;
      http.get(`/api/invest/projects/${projectSeq}/change-history/${historySeq}`).then(({data}) => {
        state.loaded = true;
        state.history = data.body.history;
        state.histories = data.body.historyList;
        state.subscribeSeq = data.body.subscribeSeq;
        state.isExistSubscribe = data.body.isExistSubscribe;
        state.isRespondedToChange = data.body.isRespondedToChange;
        state.isLatestChange = data.body.isLatestChange;
      });
    });

    const state = reactive({
      loaded: false,
      subscribeSeq: "",
      historySeq: 0,
      history: {
        historySeq: "",
        title: "",
        content: "",
        createTime: "",
      },
      histories: [],
      isExistSubscribe: false,
      isRespondedToChange: false,
      isLatestChange: false,
    });

    const projectSeq = router.app.$route.params.projectSeq;
    const historySeq = router.app.$route.params.historySeq;

    const select = () => {
      router.push({path: `/invest/${projectSeq}/changeHistory/${state.historySeq}`});
    };

    const agree = () => {
      const args = {
        investSeq: projectSeq,
        agreementFlag: "Y",
        subscribeSeq: state.subscribeSeq
      };

      http.post(`/api/invest/projects/${projectSeq}/change-history/${state.historySeq}/agreement`, args).then(() => {
        store.commit("setSwingMessage", "변경 내용에 대한 동의 처리가 완료되었습니다.");
        caches.resetHttps();
        router.push({path: `/invest/${projectSeq}`});
      });
    };

    const disagree = () => {
      store.commit("setSwingMessage", "투자 내용을 확인하신 후에 투자 취소 버튼을 클릭해주세요.");
      router.push({path: `/mypage/participant/invest/${state.subscribeSeq}`});
    };

    return {component, state, select, agree, disagree};
  }
});
</script>

<style lang="scss" scoped>
.change-history {
  background: $colorBackground;
  padding: $px35 0;

  > .container > .wrapper {
    background: #fff;
    padding: $px15;
    border: $px1 solid #eee;

    .header {
      padding: $px15;

      > .wrapper {
        position: relative;

        select {
          position: absolute;
          top: 0;
          right: 0;
          max-width: $px300;
          height: $px42;
        }
      }
    }

    .article {
      padding: $px15;

      .content {
        padding-bottom: $px15;
      }
    }

    .actions {
      text-align: center;
      padding: $px15 $px15 $px15;
      margin: 0 auto;

      .btn {
        padding: $px20 $px35;
      }
    }
  }

  &.skeleton {
    > .container > .wrapper {
      .header > .wrapper {
        > .title {
          @include skeleton;
        }

        select {
          @include skeleton;
        }
      }

      .article {
        .content > div {
          @include skeleton;
        }

        .info {
          > p > span,
          > div > span {
            @include skeleton;
          }
        }
      }

      .actions .btn {
        @include skeleton;
      }
    }
  }

  @media (max-width: 767px) {
    > .container > .wrapper {
      .header > .wrapper select {
        position: static;
        margin-bottom: $px20;
      }

      .article {
        padding-top: $px5;
      }

      .actions .btn:first-child {
        margin-bottom: $px15;
      }
    }
  }
}
</style>